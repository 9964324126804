import axios from 'axios'
import { API_ENDPOINT } from '../../config'
import Validation from '../../data/validation/validation'
import { NumberValidationRule, StringValidationRule } from '../../data/validation/rules'
import { ScrollIntoFirstError } from '../../utils/UIHelper'

const getDefaultState = () => {
    return {
        saving: false,
        transferSuccess: false,
        showTransferConfirmation: false,
        transferDesc: "",
        transferring: false,
        progress: 0,
        accountNumber: "",
        accountName: "",
        amount: "",
        bank: "",
        correspondingBank: "",
        routeNumber: "",
        correspondingBankSwiftCode: "",
        purposeOfTransfer: "",
        transferCode: "",
        sortCode: "",
        address: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.accountNumber, (error) => instance.state.errors.accountNumber = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" }  })
    validation.addValidationRule(StringValidationRule, instance.state.accountName, (error) => instance.state.errors.accountName = error, { min: { value: 6, error: "Invalid" }, max: { value: 70, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.amount, (error) => instance.state.errors.amount = error, { min: { value: 1, error: "Invalid" }, max: { value: 100000000000, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.purposeOfTransfer, (error) => instance.state.errors.purposeOfTransfer = error, { min: { value: 2, error: "Invalid" }, max: { value: 200, error: "Invalid" } })
    
    
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const transfer = instance => {
    instance.setState({
        ...instance.state,
        showTransferConfirmation: false,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/accounts/transfer",
        headers: {
            "Auth": instance.props.auth.authorization
        },
        data: {
            amount: instance.state.amount,
            accountName: instance.state.accountName,
            accountNumber: instance.state.accountNumber,
            purposeOfTransfer: instance.state.purposeOfTransfer
        }
    }).then(response => handleTransferResponse(instance, response))
        .catch(error => alert(error))
}

const handleTransferResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            let transferResult = response.data.data
            instance.setState({
                ...instance.state,
                saving: false,
                transferring: true,
                progress: 0,
                transferResult: transferResult
            }, () => {
                if (transferResult.status === 1) {
                    instance.startCountDown(100)
                }
                else {
                    instance.startCountDown(Number(transferResult.progress))
                }
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const clearCode = instance => {
    let currentState = instance.state
    instance.setState({ ...currentState, saving: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/accounts/clear/code",
        headers: {
            "Auth": instance.props.auth.authorization
        },
        data: {
            transferCode: instance.state.transferCode
        }
    }).then(response => handleClearCodeResponse(instance, response))
        .catch(error => alert(error))
}

const handleClearCodeResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            transfer(instance)
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        transfer: () => transfer(instance),
        clearCode: () => clearCode(instance),
        isValid: () => isValid(instance)
    }
}

export default Service