import React from 'react'
import NumberFormat from 'react-number-format'
import './transaction.styles.scss'

const Transaction = props => {
    let amount = props.credit > 0? props.credit: props.debit
    return (
        <div className={props.credit > 0? "transaction credit": "transaction debit"}>
            <p>
                {props.credit > 0 ? 'Credit': 'Debit'} <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} renderText={value => <>{props.currency}{value}</>} />
            </p>
            <span></span>
            <p>
                Transaction Ref: {props.reference}
            </p>
            <span></span>
            <p>
                Description: {props.description}
            </p>
            <span></span>
            <p>
                Date/Time: {new Date(props.created * 1000).toString('MMMM dd, yyyy HH:mm:ss')}
            </p>
        </div>
    )
}

export default Transaction