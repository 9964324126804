import React from 'react'
import './activate.styles.scss'

const Activate = () => {
    return (
        <div className="login">
            <h3>Account Status</h3>
            <div className="signup">
                <p>You did not have access to this account.<br/> Please contact your account officer</p>
            </div>
        </div>
    )
}

export default Activate