import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import iconLock from '../../../assets/images/padlock.png'

const LoginForm = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
                <label>Username</label>
                <input type="text" placeholder='Enter Username' name="username" value={props.username} onChange={props.onChange} />
                <span className="error">{props.errors.username}</span>
            </div>
            <div className="form-input">
                <label>Password</label>
                <input type="password" placeholder='Enter Password' name="password" value={props.password} onChange={props.onChange} />
                <span className="error">{props.errors.password}</span>
            </div>
            <div className="extras">
                <Link to="/auth/reset">Reset password</Link>
            </div>
            <div className="actions">
                <button className={props.processing ? 'disabled' : ''}>
                    <img alt="" src={iconLock}/>
                    <span>Login</span>
                </button>
            </div>
        </form>
    )
}

export default withRouter(LoginForm)