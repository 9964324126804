import React from "react";
import "./auth.styles.scss";
import "../form.styles.scss";

class AuthTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount(){
    if(localStorage.getItem('token')  === null){
      window.location = "https://websterrfinance.com"
    }
    else {
      this.setState({
        loading: false
      })
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <section className="auth">
          <div className="container">
            <header>
              <img alt="" src={window.config.logoUrl} />
            </header>
            {this.props.children}
            <footer>
              <p>© 2024 {window.config.name}. All Rights Reserved.</p>
            </footer>
          </div>
        </section>
      );
    }
    return <></>;
  }
}

export default AuthTemplate;
