import React from "react";
import { withRouter } from "react-router-dom";
import "./menu.styles.scss";
import imageDashbord from "../../../assets/icons/dashboard.svg";

const TransferMenu = (props) => {
  return (
    <div className="transfer-menu">
      <div className="header">
        <h4>Transfer Options</h4>
        <br />
      </div>
      <div className="actions">
        <div
          className="trigger"
          onClick={() => props.history.push("/transfers/international")}
        >
          <img alt="" src={imageDashbord} />
          <h4>International Transfer</h4>
        </div>
        <div
          className="trigger"
          onClick={() => props.history.push("/transfers/same")}
        >
          <img alt="" src={imageDashbord} />
          <h4>Same Bank</h4>
        </div>

        <div
          className="trigger"
          onClick={() => props.history.push("/transfers/other")}
        >
          <img alt="" src={imageDashbord} />
          <h4>Other Bank</h4>
        </div>
      </div>
    </div>
  );
};

export default withRouter(TransferMenu);
