import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

class TokenEntry extends React.Component {

    componentDidMount(){
        localStorage.setItem('token', btoa('ae1g45h56kl'))
        this.props.history.push('/auth/login')
    }

    render(){
        return(
            <></>
        )
    }
}

export default withRouter(TokenEntry)