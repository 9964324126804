import React from "react";
import "./activate.styles.scss";
import "../form.styles.scss";

const ActivateTemplate = (props) => {
  return (
    <section className="activate">
      <div>
        <div className="container">
          <header>
            <img alt="" src={window.config.logoUrl} />
          </header>
          {props.children}
        </div>
      </div>
      <div></div>
    </section>
  );
};

export default ActivateTemplate;
